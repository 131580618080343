@import '~@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0 0 10px 0;
    height: 100%;
    overflow-y: auto;
}

.mat-card .mat-divider-horizontal {
    position: relative !important;
}

.x-scroll {
    overflow-x: auto;
}

p.no-hay-resultados {
    text-align: center;
}

.app-tooltip {
    white-space: pre-line;
}

.success-snackbar {
    background-color: #51a351 !important;
    .mat-button-wrapper {
        color: white !important;
    }
}

.info-snackbar {
    background-color: #2f96b4 !important;
    .mat-button-wrapper {
        color: white !important;
    }
}

.error-snackbar {
    background-color: #bd362f;
    .mat-button-wrapper {
        color: white;
    }
}

mat-progress-bar {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

mat-card.cargando {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
